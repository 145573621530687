/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* ngx-datatable */
//@import '~@swimlane/ngx-datatable/index.css';
//@import '~@swimlane/ngx-datatable/themes/material.scss';
//@import '~@swimlane/ngx-datatable/assets/icons.css';

.color {color: rgb(155, 38, 182); }
:root {
  --ion-color-primary: #9B26B6;
  --ion-color-primary-rgb: 155,38,182;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #881db6;
  --ion-color-primary-tint: #b042b6;

  --ion-color-white: #FFFFFF;
  --ion-color-white-rgb: 255.255.255;
  --ion-color-white-contrast: #333;
  --ion-color-white-contrast-rgb: 51,51,51;
  --ion-color-white-shade: #dadada;
  --ion-color-white-tint: #FFFFFF;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}
.cursor {
  cursor: pointer;
}

.item-modal {
  .modal-wrapper {
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 300px;
  }
}

.flex-left-center {
  display: flex;
  align-items: center;
}
